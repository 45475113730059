<template>
	<form
		@submit.prevent.stop="closePopup(true)"
		class="search-wr"
		v-click-outside="closeSuggestItemsPopup"
	>
		<label
			for=""
			class="search-label"
			:class="{'search-label_focused' : focused, compact}"
			:style="compact ? 'box-shadow: none;': ''"
		>
			<div
				class="search-label__over"
				@click="checkUserRules"
				v-if="currentBasket?.collective"
			/>
			<div class="search-icon">
				<svg
					width="18"
					height="23"
					viewBox="0 0 18 23"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M9.00019 0C7.22081 0.00093786 5.48163 0.52919 4.00231 1.51803C2.523 2.50687 1.36991 3.91194 0.688716 5.55577C0.00751764 7.19959 -0.171239 9.00842 0.175026 10.7538C0.52129 12.4991 1.37704 14.1027 2.63419 15.362C4.26419 16.992 8.10019 19.35 8.32719 21.827C8.36119 22.197 8.63019 22.5 9.00019 22.5C9.37019 22.5 9.64019 22.197 9.67319 21.827C9.90019 19.35 13.7332 16.996 15.3622 15.367C16.6208 14.1082 17.478 12.5046 17.8254 10.7587C18.1727 9.01289 17.9947 7.20326 17.3138 5.55857C16.6329 3.91388 15.4797 2.50799 13.9999 1.5186C12.5201 0.529208 10.7803 0.000741466 9.00019 0ZM9.00019 12.079C8.59585 12.079 8.19547 11.9994 7.82191 11.8446C7.44834 11.6899 7.10892 11.4631 6.82301 11.1772C6.5371 10.8913 6.3103 10.5518 6.15556 10.1783C6.00083 9.80472 5.92119 9.40434 5.92119 9C5.92119 8.59566 6.00083 8.19528 6.15556 7.82172C6.3103 7.44816 6.5371 7.10873 6.82301 6.82282C7.10892 6.53691 7.44834 6.31011 7.82191 6.15538C8.19547 6.00064 8.59585 5.921 9.00019 5.921C9.81679 5.921 10.5999 6.24539 11.1774 6.82282C11.7548 7.40024 12.0792 8.1834 12.0792 9C12.0792 9.8166 11.7548 10.5998 11.1774 11.1772C10.5999 11.7546 9.81679 12.079 9.00019 12.079Z"
						fill="#FD470E"
					/>
				</svg>
			</div>

			<div
				class="search__map"
				@click="handleOpenPopap()"
			>
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M21.32 5.0503L15.32 3.0503H15.25C15.2035 3.04562 15.1566 3.04562 15.11 3.0503H14.88H14.75H14.68L9 5.0003L3.32 3.0503C3.16962 3.00071 3.00961 2.98754 2.85314 3.01188C2.69667 3.03622 2.54822 3.09737 2.42 3.1903C2.29076 3.28232 2.18527 3.40376 2.11224 3.54461C2.03921 3.68545 2.00074 3.84165 2 4.0003V18.0003C1.99946 18.2099 2.06482 18.4145 2.18685 18.5849C2.30887 18.7554 2.48138 18.8832 2.68 18.9503L8.68 20.9503C8.88145 21.016 9.09856 21.016 9.3 20.9503L15 19.0503L20.68 21.0003C20.7862 21.0147 20.8938 21.0147 21 21.0003C21.2091 21.0032 21.4132 20.9364 21.58 20.8103C21.7092 20.7183 21.8147 20.5968 21.8878 20.456C21.9608 20.3151 21.9993 20.1589 22 20.0003V6.0003C22.0005 5.79066 21.9352 5.58614 21.8132 5.41567C21.6911 5.2452 21.5186 5.11738 21.32 5.0503ZM8 18.6103L4 17.2803V5.3903L8 6.7203V18.6103ZM14 17.2803L10 18.6103V6.7203L14 5.3903V17.2803ZM20 18.6103L16 17.2803V5.3903L20 6.7203V18.6103Z"
						fill="#40AE49"
					/>
				</svg>
				<span>Карта</span>
			</div>

			<div
				class="search__compact-btn"
				@click="handleCurrentAddress()"
				v-if="compact"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="24"
					fill="none"
				>
					<path
						fill="#40AE49"
						fill-rule="evenodd"
						d="M5 11a6 6 0 1 1 12 0 6 6 0 0 1-12 0Zm15.7 8.3-3.4-3.4a8 8 0 1 0-1.4 1.4l3.4 3.4a1 1 0 0 0 1.4 0c.4-.4.4-1 0-1.4Z"
						clip-rule="evenodd"
					/>
				</svg>
			</div>

			<input
				type="text"
				id="suggest"
				name="search"
				enterkeyhint="search"
				class="search input search__map_address-input"
				autocomplete="off"
				:value="deliveryAddress"
				ref="searchAddressInput"
				@input="changeAddress($event)"
				@keypress.enter.prevent="handleCurrentAddress()"
				@focus="placeholderHideOnFocusSearchInput(); showAddressPopup();"
				@blur="placeholderShowOnBlurSearchInput()"
			>
			<label
				for="suggest"
				class="search__map_address-label"
				:class="{'search__map_address-label_active': deliveryAddress !== ''}"
			>
				{{ deliveryAddress !== '' ? 'Ваш адрес' : 'Куда доставить?' }}
			</label>
			<ul
				class="search__suggestions"
				v-show="returnCutSuggest.length && openPopupName !== 'AddressMapPopup' && !isMobile"
			>
				<li
					v-for="(suggestItem, num) in returnCutSuggest"
					:key="num"
					class="search__suggestion"
					:class="{'search__suggestion_active': suggestItem.active}"
					@click="checkSelectedSuggestion(suggestItem)"
				>
					{{ suggestItem.value }}
				</li>
			</ul>

			<span
				class="search__error"
				v-if="error"
			> {{ error }} </span>
		</label>
		<div
			class="search__btn"
			style="cursor: pointer; text-align: center;"
			@click="handleCurrentAddress()"
			v-if="!compact"
		>
			<button-preloader v-if="selectInProgress"/>
			<span v-else>Выбрать меню</span>
		</div>

		<!-- Хак для отправки формы на мобильных устройствах -->
		<input
			type="submit"
			@click="hiddenFormSubmit()"
			class="invisible-but-exists"
		/>
		<Popup :visible="openPopupName === selectRestauantPopupName">
			<Restaurants
				:restaurants="restaurants"
				@selectRestaurant="choseRest"
			/>
		</Popup>
		<Popup
			:visible="openPopupName === addressMapPopupName"
			:hideCloseBtn="true"
		>
			<MapAddress
				@selectAddress="selectPopupAddress"
				:inputAddress="address"
				:loading="loading"
			/>
		</Popup>
		<Popup
			:visible="openPopupName === openSubscribePopupName"
			:hideCloseBtn="true"
		>
			<div class="search__subscribe">
				<Subscribe @closePopup="closePopup"/>
			</div>
		</Popup>
		<Popup :visible.sync="showNoRestaurantsPopup">
			<NoRestaurants
				@changeAddress="showAddressMap()"
				@closePopup="showNoRestaurantsPopup = false;"
			/>
		</Popup>
		<AddressPopup
			v-if="addressPopupShown"
			@closePopup="closeAddressPopup"
		/>
	</form>
</template>
<script>
import MapAddress from '@/components/popup/MapAddress.vue'
import Restaurants from '@/components/popup/Restaurants.vue'
import NoRestaurants from '@/components/popup/NoRestaurants.vue'
import Subscribe from '@/components/form/Subscribe.vue';
import AddressPopup from "@/components/popup/AddressPopup";
import ButtonPreloader from '../ui/ButtonPreloader.vue';
import search from '@/mixins/search.js'
import {mapGetters} from 'vuex';

import {useYandexMetrika} from "@/composables";
import {notification} from "@/utils";
import {redirectBasedOnTimeRange} from "@/helpers";

export default {
	setup()
	{
		const {yandexMetrikaGoal} = useYandexMetrika();

		return {yandexMetrikaGoal};
	},
	components: {MapAddress, Restaurants, NoRestaurants, Subscribe, AddressPopup, ButtonPreloader},
	mixins: [search],
	props:
		{
			compact: {type: Boolean, default: false},
			canClick: {type: Boolean, default: true},
		},
	data()
	{
		return {
			coorditanes: {},
			selectRestauantPopupName: 'SelectRestauantPopup',
			addressMapPopupName: 'AddressMapPopup',
			openSubscribePopupName: 'OpenSubscribePopup',
			loading: false,
			addressPopupShown: false,
			selectInProgress: false,
			currentCity: '',
			showNoRestaurantsPopup: false,
			isMobile: window.innerWidth <= 567
		}
	},

	computed: {
		...mapGetters({
			openPopupName: 'getOpenPopupName',
			currentRestaurantId: 'getRestarauntId',
			notificationType: 'getMapPopupNotificationType',
			invalidAddress: 'getInvalidAddress',
			deliveryAddress: 'map/getDeliveryAddress',
			currentAddressCity: 'map/currentAddressCity',
			currentCityId: 'currentCityId',
			currentBasket: 'getCurrentBasket',
			timeRange: 'getTimeRange',
		}),
	},

	methods: {

		checkUserRules()
		{
			if (!this.currentBasket?.owner) return;

			const title = 'Нельзя изменять параметры коллективной корзины после ее создания';
			notification({title});
		},

		showAddressPopup()
		{
			if (window.innerWidth <= 567)
			{
				this.addressPopupShown = true;
				!!process.env.VUE_APP_MOBILE && appHelper.appPullDownTrigger(false);
				document.querySelector('body').classList.add('page-no-scroll');
				document.querySelector('html').classList.add('page-no-scroll');
			}
		},

		closeAddressPopup()
		{
			if (window.innerWidth <= 567)
			{
				this.addressPopupShown = false;
				!!process.env.VUE_APP_MOBILE && appHelper.appPullDownTrigger(true);
				document.querySelector('body').classList.remove('page-no-scroll');
				document.querySelector('html').classList.remove('page-no-scroll');
			}
		},

		/**
		 * Открывает попап для выбора адреса на карте
		 * Отправляет данные в яндекс метрику
		 */
		handleOpenPopap()
		{
			this.yandexMetrikaGoal(45209871, 'map_catalog');
			this.$store.dispatch('openPopup', this.addressMapPopupName);
		},

		/**
		 * Обработка введенного адреса
		 * скрывает подсказки, смотрит на корректность ввода адреса
		 * если все ок, смотрит сколько ресторанов вернулось
		 * если один ресторан - выбирает ресторан и редиректит в меню
		 * если много - выводит выбор ресторано
		 */
		async handleCurrentAddress()
		{
			this.selectInProgress = true;

			if (this.currentAddressCity && this.currentAddressCity.UF_XML_ID !== this.currentCityId)
			{
				await this.$store.dispatch('changeCity', this.currentAddressCity);
			}

			if (this.$store.state.map.currentAddressPrecision !== 'exact')
			{
				await this.$store.dispatch('openPopup', this.addressMapPopupName);
				this.selectInProgress = false;
				return;
			}

			let res = await this.setRestaurants();

			switch (res)
			{
				case 'noRest':
					await this.$store.dispatch('changeInvalidAddress', this.address);
					this.$store.commit('setInvalidAddress', this.address);
					this.showNoRestaurantsPopup = this.$store.state.openPopupName !== 'AddressMapPopup';
					break;
				case 'oneRest':
					this.showNoRestaurantsPopup = false;
					await this.$store.dispatch('openPopup', null);
					redirectBasedOnTimeRange(this.timeRange || 'lunch');
					break;
				case 'severalRest':
					this.showNoRestaurantsPopup = false;

					if (this.currentBasket?.collective)
					{
						await this.selectRestaurant(this.currentBasket?.restId);
						redirectBasedOnTimeRange(this.timeRange || 'lunch');
						break;
					}

					await this.$store.dispatch('openPopup', this.selectRestauantPopupName);
					break;
				case false:
					this.showNoRestaurantsPopup = true;
					break;
			}

			setTimeout(() => this.selectInProgress = false, 750);
		},

		closeSuggestItemsPopup()
		{
			this.suggestItems = false;
		},

		closePopup(openMapPopup = false)
		{
			if (openMapPopup)
			{
				this.address = this.invalidAddress;
				this.$store.dispatch('openPopup', this.addressMapPopupName);
			} else
			{
				this.$store.dispatch('openPopup', null);
			}
		},

		/**
		 * Отрабатывает когда в попапе выбрали ресторан
		 * Сохраняет его в сторе и и перекидывает в меню
		 */
		choseRest(restaurant)
		{
			this.$store.dispatch('openPopup', null);
			if (restaurant.id)
			{
				this.selectRestaurant(restaurant.id);
				if (this.$route.path !== "/menu") this.$router.push('/menu');
			}
		},

		hiddenFormSubmit()
		{
			if (window.innerWidth <= 567)
			{
				this.closePopup(true);
				this.addressPopupShown = false;
			}
		},
		/**
		 * Отркывает попап выбора адреса на карте
		 */
		showAddressMap()
		{
			this.$store.dispatch("openPopup", this.addressMapPopupName);
		},

		/**
		 * Срабатывает когда в попапе выбрали адрес
		 * скрывваем попап
		 * вставлем его в текущий адрес и обрабатываем обычным handlom
		 */
		async selectPopupAddress()
		{
			if (this.loading) return false;

			this.$store.commit('setMapPopupNotificationType', false);
			let geo = (await ymaps.geocode(this.$store.state.currentCity + ', ' + this.$store.state.map.currentAddress))
				.geoObjects.get(0);

			let precision = geo.properties.get('metaDataProperty.GeocoderMetaData.precision');
			await this.$store.dispatch('map/setCurrentAddressPrecision', precision);

			await this.handleCurrentAddress();
		},
	},

	async mounted()
	{
		// Если мы находимся на странице текущего ресторана, то нам не нужно делать дополнительных запросов
		if (this.$route.params.id && this.$route.path.includes("/rest")) return;

		// Запрос зон
		await this.getZones();

		this.setAddressIfCookieExist();
		await this.initialActions();
		this.placeholderShowOnBlurSearchInput();

		if (this.deliveryAddress)
		{
			await this.$store.dispatch('map/setCurrentAddressPrecision', 'exact');
		}
	},
}
</script>
<style lang="scss">
.invisible-but-exists
{
	max-width: 0;
	max-height: 0;
	overflow: hidden;
	opacity: .00001;
	position: absolute;
	left: -10000px;
	top: -10000px;
}

.search-wr
{
	height: 56px;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	margin-bottom: 16px;

	@media (max-width: 990px)
	{
		flex-direction: column;
	}
}

.search-label
{
	position: relative;
	height: 100%;
	width: 100%;
	max-width: 613px;
	display: flex;
	border-radius: 8px;
	border: 1px solid #E5E5E7;
	box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.15);
	background-color: #fff;
	margin-right: 16px;
	align-items: center;

	&.compact
	{
		max-width: 691px;
		width: 691px;
	}

	&_focused
	{
		background: $lightGreenBackground;
		outline: unset;

		& .search__map
		{
			background: $lightGreenBackground;
		}
	}

	&:hover
	{
		background: $lightGreenBackground;

		& .search__map
		{
			background: $lightGreenBackground;
		}
	}
}

.search-label__over
{
	position: absolute;
	z-index: 2;
	width: 100%;
	height: 100%;
	left: 0;
	right: 0;
}

.search_city
{
	font-family: $mainFontMediumNew;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	background-color: #fff;
	display: flex;
	align-items: center;
	padding-right: 4px;
}

.search
{
	height: 100%;
	flex: 1;
	font-family: $mainFontMediumNew;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	color: $textColor;
	outline: none;
	border: none;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	background: transparent;

	@include ease(.125s);

	&__city-found
	{
		font-size: 16px;

		& span
		{
			color: $green;
			font-size: inherit !important;
		}
	}

	&.error
	{
		color: #fd470e;
	}

	&__error
	{
		color: $red;
	}

	&::placeholder
	{
		color: $textColor;
	}

	&__compact-btn
	{
		position: absolute;
		right: 20px;
		bottom: 8px;
		cursor: pointer;
	}

	&__map
	{
		font-family: $mainFontMediumNew;
		color: $green;
		cursor: pointer;
		font-size: 14px;
		line-height: 14px;
		position: absolute;
		right: 20px;
		display: flex;
		align-items: center;
		height: 100%;
		background: #fff;

		&:before
		{
			content: '';
			display: block;
			position: absolute;
			left: -22px;
			height: 32px;
			width: 1px;
			background: $greyBorder;
		}

		& svg
		{
			margin-right: 6px;
		}

		@media (max-width: 767px)
		{
			font-size: 14px;
			right: 8px;
			padding-left: 8px;

			&:before
			{
				left: 0;
			}

			& svg
			{
				display: none;
			}
		}
	}

	&__subscribe
	{
		padding: 32px 40px;
		width: 738px;
		height: 270px;
	}

	&-label
	{
		padding-right: 120px;

		.search__map_address
		{
			&-input
			{
				font-family: $mainFontMediumNew;
				font-weight: 500;
				font-size: 16px;
				line-height: 14px;
				width: 100%;
				height: 24px;
				border: none;
				background: none;
				position: relative;
				bottom: 9px;
				top: 5px;
				padding: 0;
				margin-right: 0;

				&:focus + .search__map_address-label
				{
					font-family: $mainFontNew;
					font-weight: 400;
					font-size: 11px;
					color: #979797 !important;
					transform: translateY(-13px);
					background: transparent;
				}
			}

			&-label
			{
				font-family: $mainFontMediumNew;
				font-weight: 500;
				font-size: 16px;
				line-height: 14px;
				width: fit-content;
				height: auto;
				display: flex;
				align-items: center;
				position: absolute;
				left: 56px;
				background: transparent;
				color: #3D4248 !important;
				transition: all .3s ease-in-out;
				pointer-events: none;

				&_active
				{
					font-family: $mainFontNew;
					font-weight: 400;
					font-size: 11px;
					color: #979797 !important;
					transform: translateY(-13px);
					background: transparent;
				}
			}
		}
	}
}

.search-label.compact
{

	width: 100%;

	margin-left: 60px;
	margin-right: 60px;

	.search__map
	{
		right: 64px;
	}

	.search__map_address-input
	{
		padding-right: 20px;
	}

}

.search-icon
{
	line-height: 0;
	z-index: 1;
	width: 56px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px
}

.search__btn
{
	width: 310px;
	max-width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0 10px 16px 0 rgba(34, 60, 80, 0.2);
	background: $red;
	color: #fff;
	font-size: 16px;
	line-height: 20px;
	font-weight: bold;
	border: none;
	outline: none;
	border-radius: 8px;
	font-family: $mainFontBoldNew;
	white-space: nowrap;
	z-index: 1;
	transition: background-color .3s ease;

	&.loading
	{
		background: #E5E5E7;
	}

	&:hover
	{
		background: $redHover;
	}
}

.search__suggestion
{
	padding-bottom: 8px;
	cursor: pointer;
	@include ease(.125s);

	font-family: $mainFontMediumNew;
	color: $textColor;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	border-bottom: 1px solid $greyDelimiter;

	&:last-child
	{
		padding-bottom: 0;
		border-bottom: none;
	}

	&_active
	{
		background-color: rgba(#40AE49, .1);
	}
}

.search__suggestions
{
	margin: 0;
	display: flex;
	flex-direction: column;
	gap: 8px;
	z-index: 14;
	position: absolute;
	background: #FFFFFF;
	border: 1px solid $greyDelimiter;
	border-radius: 8px;
	width: 100%;
	padding: 16px;
	top: calc(100% + 3px);
}

.search__error
{
	position: absolute;
	top: calc(100% + 3px);
}

@media (max-width: 1279px)
{
	.search,
	.search_city
	{
		font-size: 16px;
	}
}

@media (max-width: 990px)
{
	.compact
	{
		max-width: unset;
		min-width: unset;
		width: 100%;
	}

	.search
	{
		&__btn
		{
			width: 100%;
			height: 56px;
		}

		margin-right: 58px;
		padding: 0;
		font-size: 14px;
		line-height: 17px;
		text-overflow: ellipsis;
		overflow: hidden;

		&.error
		{
			font-size: 8px;
		}

		&__error
		{
			display: none;
		}

		&__subscribe
		{
			width: 680px;
			height: 270px;
		}
	}

	.search-wr
	{
		height: auto;
		margin-bottom: 0;
	}
	.search-label
	{
		height: 56px;
		max-width: 100%;
		margin-right: 0;
		margin-bottom: 16px;
		align-items: center;
	}
	.search_city
	{
		margin-right: 2px;
		font-size: 14px;
		display: none;
	}
	.search__on-map-adaptive
	{
		display: block;
	}
}

@media (max-width: 990px)
{
	.search-label.compact
	{

		width: calc(100vw - 64px);
		margin: 0;

		.search__map
		{
			right: 64px;

			svg
			{display: block;}
		}

	}
}

@media (max-width: 767px)
{
	.search
	{
		&__subscribe
		{
			width: 100%;
		}

		&-label
		{
			padding-right: 66px;
		}

		&__btn
		{
			width: 100%;
		}
	}

	.search-label.compact
	{
		.search__map_address-input
		{padding-right: 76px;}
	}
}

@media (max-width: 567px)
{
	.search
	{
		&__btn
		{
			height: 48px;
			padding: 14px;
			font-size: 14px;
			line-height: 20px;
		}

		&-label
		{
			margin-bottom: 8px;
			height: 48px;

			input
			{
				font-size: 14px !important;
				margin-left: -4px;
			}

			.search__map_address-label
			{
				left: 17%;
			}
		}
	}

	.search-label.compact
	{
		.search__map_address-input
		{padding-right: 20px;}

		.search__map_address-label
		{left: 52px;}

		.search__map
		{
			right: 46px;

			span
			{display: none;}

			svg
			{display: block;}

			&::before
			{display: none;}
		}

		.search__compact-btn
		{
			bottom: 4px;
			right: 15px;
		}

	}
}

@media (max-width: 374px)
{
	.search
	{

		&-main-wrapper
		{
			padding-bottom: 22px;
		}

		&__map
		{
			font-size: 11px;
		}

		&-icon
		{
			width: 44px;
		}

		&-label
		{

			.search__map_address-label
			{
				left: 15%;
			}
		}

	}

	.search-label.compact
	{

		.search__map_address-label
		{
			left: 42px;
		}

	}
}
</style>
